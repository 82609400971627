import React, { useState, useEffect } from 'react';
import axios from "axios";
import { graphql, useStaticQuery, Link, navigateTo } from 'gatsby';
import Img from "gatsby-image";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';


import Layout from '../components/layout';

export const query = graphql`
query($slug: String, $locale: String){
    contentfulOpenPosition(slug: {eq: $slug} node_locale: {eq: $locale}){
        id
        slug
        title
        experience_list
        responsabilities_list
        formation_text{
            id
            formation_text
        }
        node_locale
    }
    office: file( relativePath: { eq: "joinUsOffice.jpg"} ) {
        id
        childImageSharp { 
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
    }
}`

import styles from "../sass/pages/contact.module.scss";
import how from "../assets/img/jobs-icons/how-we-work.svg";
import team from "../assets/img/jobs-icons/team.svg";
import benefits from "../assets/img/jobs-icons/benefits.svg";


class Position extends React.Component {

    locale: any;
    positionSlug: string;
    domRef: any;
    constructor(props: any) {
        super(props);
        this.locale = props.data.contentfulOpenPosition.node_locale;
        this.positionSlug = props.data.contentfulOpenPosition.slug;
        this.domRef = React.createRef();

        this.state = { feedbackMsg: null, position: this.positionSlug };
    }

    handleAttachment = e => {
        this.setState({ [e.target.name]: e.target.files[0] });
    };

    encodeData = (data: any, formFields: any) => {
        const formData = new FormData();

        Object.keys(data).forEach((key) => formData.append(key, data[key]));
        Object.keys(this.refs).map((key) => formData.append(key, this.refs[key].value));
        return formData;
    };

    handleSubmit(event: Event) {
        event.preventDefault();
        const form = event.target;

        // //add form field to form data
        const formData = {};
        Object.keys(this.refs).map(key => (formData[key] = this.refs[key].value));
        formData.position = this.positionSlug;
        const axiosOptions = {
            url: this.props.location.pathname,
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: this.encodeData({
                'file': this.state.file
            }, formData)
        };

        //post no netlify using axios
        axios(axiosOptions)
            .then(response => {
                this.setState({
                    feedbackMsg: "Form submitted successfully!",
                });
                this.domRef.current.reset();
                navigateTo(form.getAttribute("action"));
            })
            .catch(err =>
                this.setState({
                    feedbackMsg: "Form could not be submitted.",
                })
            );
    }

    handlePositionChange = e => {
        this.setState({
            position: this.positionSlug
        });
    }

    render() {
        return (
            <Layout color="">
                <article className={'container-small ' + styles.article + " " + "space"}>
                    <div className="container-large">
                        <h1>{this.props.data.contentfulOpenPosition.title}</h1>
                        <div className="space-small"></div>
                        <div className={"grid-cards-2 " + styles.openPosition}>
                            <div>
                                <h4><FormattedMessage id="formation" values={{ breakLine: <br /> }} /></h4>
                                <p className="p-small">{this.props.data.contentfulOpenPosition.formation_text.formation_text}
                                </p>
                                <div className="space-tiny"></div>
                                <h4><FormattedMessage id="experience" values={{ breakLine: <br /> }} /></h4>
                                {this.props.data.contentfulOpenPosition.experience_list.map((value, i) => {
                                    return (<p className="p-small" key={i}>{value}</p>)
                                })}
                            </div>
                            <div>
                                <h4><FormattedMessage id="responsibilities" values={{ breakLine: <br /> }} /></h4>
                                {this.props.data.contentfulOpenPosition.responsabilities_list.map((value, i) => { return (<p className="p-small" key={i}>{value}</p>) })}
                            </div>
                        </div>
                    </div>
                    <div className="blueContainer">
                        <div className={styles.topLineWhite}></div>
                        <div className={"container-large " + styles.whiteBackground}>
                            <div className={"grid-cards-2-1 " + styles.container}>
                                <div>
                                    <h2>
                                        <FormattedMessage id="position_title_1" values={{ breakLine: <br /> }} />
                                    </h2>
                                </div>
                            </div>
                            <div className="space"></div>
                            <div>
                                <img src={how} alt="" />
                                <h3 className={styles.joinUsTitle}>
                                    <FormattedMessage id="position_title_2" values={{ breakLine: <br /> }} />
                                </h3>
                            </div>
                            <div className="space-small"></div>
                            <div className={"grid-cards-2-1 "}>
                                <div>
                                    <h4><FormattedMessage id="position_subtitle_1" values={{ breakLine: <br /> }} /></h4>
                                    <p>
                                        <FormattedMessage id="position_paragraph_1" values={{ breakLine: <br /> }} />
                                    </p>
                                </div>
                                <div>
                                    <h4><FormattedMessage id="position_subtitle_2" values={{ breakLine: <br /> }} /></h4>
                                    <p>
                                        <FormattedMessage id="position_paragraph_2" values={{ breakLine: <br /> }} />
                                    </p>
                                </div>
                                <div>
                                    <h4><FormattedMessage id="position_subtitle_3" values={{ breakLine: <br /> }} /></h4>
                                    <p>
                                        <FormattedMessage id="position_paragraph_3" values={{ breakLine: <br /> }} />
                                    </p>
                                </div>
                            </div>

                            <div className="space"></div>
                            <div>
                                <img src={team} alt="" />
                                <h3 className={styles.joinUsTitle}>
                                    <FormattedMessage id="position_title_3" values={{ breakLine: <br /> }} />
                                </h3>
                            </div>
                            <div className="space-small"></div>
                            <div className={"grid-cards-2-1 "}>
                                <div>
                                    <h4><FormattedMessage id="position_subtitle_4" values={{ breakLine: <br /> }} /></h4>
                                    <p>
                                        <FormattedMessage id="position_paragraph_4" values={{ breakLine: <br /> }} />
                                    </p>
                                </div>
                                <div>
                                    <h4><FormattedMessage id="position_subtitle_5" values={{ breakLine: <br /> }} /></h4>
                                    <p>
                                        <FormattedMessage id="position_paragraph_5" values={{ breakLine: <br /> }} />
                                    </p>
                                </div>
                            </div>

                            <div className="space"></div>
                            <div>
                                <img src={benefits} alt="" />
                                <h3 className={styles.joinUsTitle}>
                                    <FormattedMessage id="position_title_4" values={{ breakLine: <br /> }} />
                                </h3>
                            </div>
                            <div className="space-small"></div>
                            <div className={"grid-cards-2-1 "}>
                                <div>
                                    <div className={styles.picture}>
                                        <figure className={styles.picture}>
                                            <Img fluid={this.props.data.office.childImageSharp.fluid} alt="Office Picture" />
                                        </figure>
                                    </div>
                                </div>
                                <div>
                                    <h4><FormattedMessage id="position_subtitle_6" values={{ breakLine: <br /> }} /></h4>
                                    <p>
                                        <FormattedMessage id="position_paragraph_6" values={{ breakLine: <br /> }} />
                                    </p>
                                </div>
                            </div>
                            <div className="space"></div>
                        </div>
                        <div className={styles.bottomLineWhite}></div>
                    </div>

                    <div className="space"></div>
                    <div className="container-large">
                        <h2><FormattedMessage id="apply" values={{ breakLine: <br /> }} /> </h2>
                        <div className="space"></div>
                        <form ref={this.domRef} name="join" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/message" onSubmit={event => this.handleSubmit(event)}>
                            <input type="hidden" ref="form-name" name="form-name" value="join" />
                            <div hidden aria-hidden="true">
                                <label>
                                    <FormattedMessage id="human" values={{ breakLine: <br /> }} />
                                    <input name="bot-field" />
                                </label>
                            </div>
                            <div hidden>
                                <input type="text" name="position" ref="position" onChange={this.handlePositionChange} value={this.state.position}/>
                            </div>
                            <div className={"grid-cards-2-1 "}>
                                <div>
                                    <label className={"p-small"}><FormattedMessage id="contact_name" /></label>
                                    <input type="text" id="name" name="name" ref="name" className={styles.inputContact} required />
                                </div>

                                <div>
                                    <label className={"p-small"}><FormattedMessage id="contact_email" /></label>
                                    <input type="email" id="email" name="email" ref="email" className={styles.inputContact} required />
                                </div>

                                <div>
                                    <label className={"p-small"}><FormattedMessage id="contact_phone" /></label>
                                    <input type="phone" id="phone" name="phone" ref="phone" className={styles.inputContact} required />
                                </div>

                                <div>
                                    <label className={"p-small"}><FormattedMessage id="attach_files_title" /></label>
                                    <input type="file" id="file" name="file" onChange={this.handleAttachment} className={styles.dragAndDrop + " " + "p-small"} />
                                </div>

                            </div>
                            <label className={"p-small"} htmlFor="text"><FormattedMessage id="text" /></label>
                            <textarea name="tell_us_something" id="tell_us_something" ref="tell_us_something" ></textarea>

                            <label className={"p-small"} htmlFor="text"><FormattedMessage id="how" /></label>
                            <textarea name="how_did_you_hear_about_us" id="how_did_you_hear_about_us" ref="how_did_you_hear_about_us" ></textarea>

                            <div className={"grid-cards-2-1 " + styles.keepInfo}>
                                <div>
                                    <input type="checkbox" id="info" name="info" ref="info" />
                                    <label htmlFor="info" className={styles.info}><FormattedMessage id="keep_info" values={{ breakLine: <br /> }} /></label>
                                </div>
                                <div>
                                    <button className={"btn btn-main " + styles.contactButton} type="submit"><FormattedMessage id="contact_send_button" /></button>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div className="space"></div>
                </article>
            </Layout>
        )
    };
}
export default injectIntl(Position);